
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'area-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    methods: {
      usePlace(place) {
        if (place) {
          this.markers.pop();
          this.coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          this.center = this.coordinates;
          this.markers.push({
            id: this.markers.length + 1,
            position: this.coordinates,
          });
          this.coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
        }
      },
      updateBounds(rectangle) {
        debugger;
        console.log('lat : ' + rectangle);

        // console.log('lng : ' + rectangle.latLng.lng());

        // var bounds = rectangle.getBounds()!.getNorthEast();
        // console.log(bounds);
        // console.log(this.bounds);
      },
      updateCoordinates(location) {
        this.coordinates = {
          lat: location.latLng.lat(),
          lng: location.latLng.lng(),
        };
      },
      addMarker(location) {
        // debugger;
        console.log(location);
        this.markers.pop();
        this.coordinates = {
          lat: location.latLng.lat(),
          lng: location.latLng.lng(),
        };
        this.markers.push({
          id: this.markers.length + 1,
          position: this.coordinates,
        });
      },
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);
      const coordinates: any = ref({ lat: '', lng: '' });

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const markers = [
        {
          id: 1,
          position: {
            lat: 33.2209264,
            lng: 43.714387,
          },
        },
      ];

      const bounds = [
        {
          north: 33.685,
          south: 33.671,
          east: -116.234,
          west: -116.251,
        },

        {
          north: 33.69,
          south: 33.69,
          east: -116.25,
          west: -116.28,
        },
      ];
      const options = {
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
      };

      const center = { lat: 33.2209264, lng: 43.714387 };

      const areaSchema = Yup.object().shape({
        governorateid: Yup.string()
          .notOneOf(['0'], () => translate('GOVERNORATE_IS_REQUIRED_FIELD'))
          .required(() => translate('GOVERNORATE_IS_REQUIRED_FIELD'))
          .label('Governorate'),
        titleAr: Yup.string()
          .required(() => translate('AR_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ar'),
        titleEn: Yup.string()
          .required(() => translate('EN_TITLE_IS_REQUIRED_FIELD'))
          .label('Title En'),
        titleKu: Yup.string()
          .required(() => translate('KU_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ku'),
        latitude: Yup.string()
          .required(() => translate('LATITUDE_IS_REQUIRED_FIELD'))
          .label('Latitude'),

        longitude: Yup.string()
          .required(() => translate('LONGITUDE_IS_REQUIRED_FIELD'))
          .label('Longitude'),
      });
      const onSubmitCreate = async (values) => {
        values.title = [];
        values.description = [];
        values.title.push({ languageCode: 'en', title: values.titleEn });
        values.title.push({ languageCode: 'ar', title: values.titleAr });
        values.title.push({ languageCode: 'ku', title: values.titleKu });

        values.governorateid = String(values.governorateid);

        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        values.inCity = values.inCity == true ? true : false;
        const payload = {
          inCity: values.inCity,
          title: values.title,
          governorateId: values.governorateid,
          latitude: values.latitude,
          longitude: values.longitude,
        };
        await store.dispatch(Actions.CREATE_AREA, payload);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_AREA'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'areas-listing' });
          });
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('areaCreating'), [
          translate('assets'),
        ]);
      });

      await store.dispatch(Actions.GET_ALL_GOVERNORATES, { limit: 50 });

      return {
        governorates: computed(() => store.getters.allGovernoratesList),
        coordinates,
        center,
        markers,
        bounds,
        options,
        onSubmitCreate,
        selectedItem,
        submitButton,
        translate,
        areaSchema,
        goBack,
        can,
      };
    },
  });
